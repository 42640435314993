<template>
  <div>
    <v-container class="main-container d-flex flex-wrap">
      <div class="col-12 col-mm-8 pa-1 mt-8 ">

        <div style="position:relative;" class="d-flex justify-center justify-lg-space-around align-center pa-0">
          <label >
            <input type="file" ref="file" @change="upload" style="display: none">
            <div v-if="profile" style="position: relative">
              <!--          <v-skeleton-loader
                            v-if="loading"
                            type="image"
                            style="border-radius: 50%"
                            min-width="50%"
                            max-width="230px"
                        ></v-skeleton-loader>-->
              <div class="profileImage">
                <img width="100%" height="100%" style="object-fit: cover" v-if="profile.image" :src="$store.state.thumbnail+profile.image" >
                <img height="100%" width="100%" style="object-fit: cover" v-else :src="$store.state.thumbnail+profile.image" >
              </div>

              <img class="cameraIcon" src="../assets/images/profile/cameraIcon.png">
            </div>
          </label>
        </div>

        <div class="d-flex justify-space-between align-center">
          <div class="mr-5">
            <p v-if="profile" class="grey--text text-center">{{profile.tell}}</p>
            <p v-if="profile" class="grey--text">{{profile.fName}} {{profile.lName}}</p>
          </div>
          <div class="ml-5">
            <p v-if="profile" class="grey--text profile__price">کد معرف : <span  class="primary--text">{{profile.moarefCode}}</span> </p>
          </div>
        </div>

        <div class="profile__worksmenu ">
          <div class="d-flex justify-center">


            <div v-if="profile" class="profile__profileedit">
              <v-text-field
                  :rules="[rules.required, rules.counter]"
                  counter="25"
                  dark
                  :disabled="loading"
                  label="نام"
                  maxlength="26"
                  v-model="profile.fName"
              ></v-text-field>

              <v-text-field
                  :rules="[rules.required, rules.counter]"
                  counter="25"
                  dark
                  :disabled="loading"
                  label="نام خانوادگی"
                  maxlength="26"
                  v-model="profile.lName"
              ></v-text-field>

              <v-btn
                  width="300px"
                  color="primary"
                  class="mt-8"
                  :loading="loading"
                  @click="submit"
              >
                <template v-slot:loader>
        <span class="custom-loader">
          <v-icon light>fa-refresh</v-icon>
        </span>
                </template>
                ثبت ویرایش
              </v-btn>
            </div>
          </div>
        </div>


      </div>
      <div class="col-12 col-mm-4 py-6 px-lg-6 px-2">

        <LiveSearch></LiveSearch>

        <LastPosts></LastPosts>

        <RelatedPosts></RelatedPosts>

      </div>
    </v-container>


  </div>
</template>

<script>

import LastPosts from "../components/LastPosts";
import RelatedPosts from "../components/RelatedPosts";
import LiveSearch from "../components/LiveSearch";
import axios from "axios";
export default {
  name: 'App',
  components : {
    LastPosts,
    RelatedPosts,
    LiveSearch
  },
  data(){
    return {
      loading:true,
      name,
      loadingbtn : false,
      family: '',
      prevRoute : {},
      rules: {
        required: value => !!value || 'پر کردن فیلد الزامی است',
        counter: value => value.length <= 25 || 'حداکثر ۲۵ کاراکتر مجاز می باشد',
      },
      profile: null,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  created() {
    axios.post(this.$store.state.api + 'getprofile', {
      userId: this.$store.state.userId,
    }).then(response => {
      this.profile = response.data.data;
      this.loading=false;
    });

  },
  metaInfo() {
    return {
      title:'ویرایش پروفایل',
      link: [{rel: 'canonical', href: this.$store.state.url+'/profile'}]
    }
  },
  methods : {
    upload: function () {
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('file', this.file);
      axios.post(this.$store.state.api+'imageupload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
            if (response.data.result) {
              this.profile.image = response.data.data;
              axios.post(this.$store.state.api+'setprofile', {
                userId: this.profile.userId,
                image: this.profile.image
              }).then(response => {
                this.profile = response.data.data;
                this.$store.state.profileChange = true;
              });
            }
          }
      );
    },
    submit: function () {
      this.loading = true;
      axios.post(this.$store.state.api + 'setprofile', this.profile).then(response => {
        this.profile = response.data.data;
        this.loading = false;
        this.$store.state.profileChange = true;
        this.$store.state.notify.text='ویرایش موفق بود';
        this.$store.state.notify.is=true;
      });
    }
  }
};
</script>

<style lang="scss">

.cameraIcon {
  position: absolute;
  bottom: 0px;
  right: 10%;
  width: 35px;
  cursor: pointer;
  opacity: 0.8;
  transition: 1s;
}

.profileImage {
  object-fit: cover;
  width: 220px;
  overflow: hidden;
  height: 220px;
  border-radius: 100%;
  background-color: #4d4f60;
  border: 5px solid #555555;
  box-shadow: 0px 0px 10px rgb( 0 0 0 / 30%);
  cursor: pointer;
  transition: 0.5s;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
