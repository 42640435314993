<template>
  <div>

    <p class="side-content__title">پربازدیدترین مطالب</p>
    <div class="side-content">

      <div v-if="!lastpostloading">
        <div v-for="item in lastposts" :key="item.id" >
        <router-link :to="{name:'single',params:{id:item.id,title:item.title}}" custom v-slot="{ navigate , href }">
        <div @click="navigate" role="link" class="d-flex align-center side-content__spaces" >
          <div class="side-content__img ml-5">
            <a :href="href" role="link">
            <img :src="$store.state.smallthumbnail + item.image" class="side-content__img"/>
            </a>
          </div>
          <div>
            <a :href="href" role="link" ><p class="side-content__txt" >{{item.title}}</p></a>
            <!--          <p class="side-content__subtxt" >subtitle</p>-->
          </div>
        </div>
        </router-link>
        </div>
      </div>




      <div v-else class="d-flex align-center side-content__spaces" v-for="n in 4" :key="n">
        <div class="side-content__img ml-5">
          <v-skeleton-loader
              dark
              class="ml-5"
              style="width: 42px;border-radius: 4px;height: 64px;min-width: 42px"
              type="image"
          ></v-skeleton-loader>
        </div>
        <div>
          <v-skeleton-loader
              dark
              min-width="150px"
              type="text@3"
          ></v-skeleton-loader>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'App',

  data() {
    return {
      lastposts : [],
      lastpostloading : true,
    }
  },
created() {
  axios.get(this.$store.state.api + 'homepopularpost').then(response => {
    this.lastposts = response.data.data;
    this.lastpostloading = false;
  })
}
};
</script>
